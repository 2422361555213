<template>
  <van-popup v-model:show="model" :style="{ height: '100%' }" position="top">
    <video-details v-if="model" :video-id="selectedVideo?.home_videos?.id" @close="model = false"/>
  </van-popup>
</template>

<script setup>
import VideoDetails from "@/components/dynamic_components/video-slider/VideoDetails"
import {track} from "@/composables/useTrackEvent";
import {TRACKERS, EVENTS} from "@/constants/events";
import {useEventBus} from "@vueuse/core/index";

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  }
})
const model = defineModel()
const {on: openVideoDailog} = useEventBus(EVENTS.VIDEO_DIALOG);

openVideoDailog((item) => onSelectVideo(item))


const selectedVideo = ref(null)
const onSelectVideo = (item) => {
  selectedVideo.value = item
  model.value = true
}
</script>
  