export const MENU_TYPES = {
    MAKEUP: 'makeup',
    PERFUME: 'perfume',
    CARE: 'care',
    LENSES: 'lenses',
    DEVICES: 'devices',
    PREMIUM: 'premium',
    GIFTS: 'gifts',
    NAILS: 'nails',
    VITAMINS: 'vitamins-supplements',
    HOME_SCENTS: 'home-scents',
}

export const BANNERS_BY_MENU_TYPES = {
    [MENU_TYPES.MAKEUP]: {
        banners: [
            {image: '/images/categories/11.png', seo_url: 'dior-makeup'},
            {image: '/images/categories/16.png', seo_url: 'calla-makeup'}
        ],
        brands: [
            {
                "manufacturer_id": "323",
                "name": "Topface",
                "en_name": "Topface",
                "ar_name": "توب فيس",
                "image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/17061159_Topface-500x500.png",
                "background_image": "",
                "original_image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/17061159_Topface-500x500.png",
                "background_original_image": "",
                "status": true,
                "seo_url": "topface"
            },
            {
                "manufacturer_id": "1213",
                "name": "Charlotte Tilbury",
                "en_name": "Charlotte Tilbury",
                "ar_name": "شارلوت تلبوري",
                "image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/74052124_CharlotteTilbury-500x500.png",
                "background_image": "",
                "original_image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/74052124_CharlotteTilbury-500x500.png",
                "background_original_image": "",
                "status": true,
                "seo_url": "charlotte-tilbury"
            },
            {
                "manufacturer_id": "1129",
                "name": "CALLA Makeup",
                "en_name": "CALLA Makeup",
                "ar_name": "كالا ميك اب",
                "image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/88979340_CallaMakeup-500x500.png",
                "background_image": "",
                "original_image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/88979340_CallaMakeup-500x500.png",
                "background_original_image": "",
                "status": true,
                "seo_url": "calla-makeup"
            },
            {
                "manufacturer_id": "11",
                "name": "Maybelline",
                "en_name": "Maybelline",
                "ar_name": "ميبيلين",
                "image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/97980104_Maybelline-500x500.png",
                "background_image": "",
                "original_image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/97980104_Maybelline-500x500.png",
                "background_original_image": "",
                "status": true,
                "seo_url": "maybelline"
            },
            {
                "manufacturer_id": "15",
                "name": "NARS",
                "en_name": "NARS",
                "ar_name": "نارس",
                "image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/1380750_NARS-500x500.png",
                "background_image": "",
                "original_image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/1380750_NARS-500x500.png",
                "background_original_image": "",
                "status": true,
                "seo_url": "nars"
            },
            {
                "manufacturer_id": "1273",
                "name": "MESAUDA",
                "en_name": "MESAUDA",
                "ar_name": "ميساودا",
                "image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/1677661542_undefined_85358276_MESAUDA-500x500.png",
                "background_image": "",
                "original_image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/1677661542_undefined_85358276_MESAUDA-500x500.png",
                "background_original_image": "",
                "status": true,
                "seo_url": "mesauda"
            }
        ]
    },
    [MENU_TYPES.PERFUME]: {
        banners: [
            {image: '/images/categories/15.png', seo_url: 'dior'},
        ],
        brands: [
            {
                "manufacturer_id": "110",
                "name": "Dior",
                "en_name": "Dior",
                "ar_name": "ديور",
                "image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/62423569_Dior1-500x500.png",
                "background_image": "",
                "original_image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/62423569_Dior1-500x500.png",
                "background_original_image": "",
                "status": true,
                "seo_url": "dior"
            },
            {
                "manufacturer_id": "1088",
                "name": "Aroub",
                "en_name": "Aroub",
                "ar_name": "عروب",
                "image": "https://d3e7ardzpaj3y4.cloudfront.net/image/brands/60444742_1716987024_64d4452ee21da5c67377720987fe516d-500x500.png",
                "background_image": "",
                "original_image": "https://d3e7ardzpaj3y4.cloudfront.net/image/brands/60444742_1716987024_64d4452ee21da5c67377720987fe516d-500x500.png",
                "background_original_image": "",
                "status": true,
                "seo_url": "aroub"
            },
            {
                "manufacturer_id": "155",
                "name": "YSL",
                "en_name": "YSL",
                "ar_name": "ايف سان لوران",
                "image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/80334792_YSL-500x500.png",
                "background_image": "",
                "original_image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/80334792_YSL-500x500.png",
                "background_original_image": "",
                "status": true,
                "seo_url": "ysl"
            },
            {
                "manufacturer_id": "92",
                "name": "Calvin Klein",
                "en_name": "Calvin Klein",
                "ar_name": "كالفن كلاين",
                "image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/81328350_Calvin Klein1-500x500.png",
                "background_image": "",
                "original_image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/81328350_Calvin Klein1-500x500.png",
                "background_original_image": "",
                "status": true,
                "seo_url": "calvin-klein"
            },
            {
                "manufacturer_id": "1192",
                "name": "BLANCO",
                "en_name": "BLANCO",
                "ar_name": "بلانكو",
                "image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/1681376817_undefined_16369612_blanco-500x500.png",
                "background_image": "",
                "original_image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/1681376817_undefined_16369612_blanco-500x500.png",
                "background_original_image": "",
                "status": true,
                "seo_url": "blanco"
            },
            {
                "manufacturer_id": "85",
                "name": "Gucci",
                "en_name": "Gucci",
                "ar_name": "قوتشي",
                "image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/46552895_Gucci-1-500x500.png",
                "background_image": "",
                "original_image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/46552895_Gucci-1-500x500.png",
                "background_original_image": "",
                "status": true,
                "seo_url": "gucci"
            }
        ]
    },
    [MENU_TYPES.CARE]: {
        banners: [
            {image: '/images/categories/17.png', seo_url: 'care/face-care/cleansers--toners'},
            {image: '/images/categories/12.png', seo_url: 'care/face-care/face-scrubs'}
        ],
        brands: [
            {
                "manufacturer_id": "1403",
                "name": "Anua",
                "en_name": "Anua",
                "ar_name": "أنوا",
                "image": "https://d3e7ardzpaj3y4.cloudfront.net/image/brands/30193973_1706092928_fc94540eef07bbe86a9ebc4ef43ee236-500x500.png",
                "background_image": "",
                "original_image": "https://d3e7ardzpaj3y4.cloudfront.net/image/brands/30193973_1706092928_fc94540eef07bbe86a9ebc4ef43ee236-500x500.png",
                "background_original_image": "",
                "status": true,
                "seo_url": "anua"
            },
            {
                "manufacturer_id": "1347",
                "name": "BEAUTY OF JOSEON",
                "en_name": "BEAUTY OF JOSEON",
                "ar_name": "بيوتي اوف جوسون",
                "image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/758703_BEAUTYOFJOSEON-500x500.png",
                "background_image": "",
                "original_image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/758703_BEAUTYOFJOSEON-500x500.png",
                "background_original_image": "",
                "status": true,
                "seo_url": "beauty-of-joseon"
            },
            {
                "manufacturer_id": "1282",
                "name": "ElishaCoy",
                "en_name": "ElishaCoy",
                "ar_name": "ايليشاكوي",
                "image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/1679565116_undefined_27393995_elishacoyLOGO-500x500.png",
                "background_image": "",
                "original_image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/1679565116_undefined_27393995_elishacoyLOGO-500x500.png",
                "background_original_image": "",
                "status": true,
                "seo_url": "elishacoy"
            },
            {
                "manufacturer_id": "316",
                "name": "Vaseline",
                "en_name": "Vaseline",
                "ar_name": "فازلين",
                "image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/23762134_Vaseline-500x500.png",
                "background_image": "",
                "original_image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/23762134_Vaseline-500x500.png",
                "background_original_image": "",
                "status": true,
                "seo_url": "vaseline"
            },
            {
                "manufacturer_id": "704",
                "name": "The Ordinary",
                "en_name": "The Ordinary",
                "ar_name": "ذا اورديناري",
                "image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/66089951_The Ordinary logo-500x500.png",
                "background_image": "",
                "original_image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/66089951_The Ordinary logo-500x500.png",
                "background_original_image": "",
                "status": true,
                "seo_url": "the-ordinary"
            }
        ]
    },
    [MENU_TYPES.LENSES]: {
        banners: [
            {image: '/images/categories/6.png', seo_url: 'lenses'},
            {image: '/images/categories/4.png', seo_url: 'lenses/beauteous-cosmetic-contact-lenses'}
        ],
        brands: []
    },
    [MENU_TYPES.DEVICES]: {
        banners: [
            {image: '/images/categories/14.png', seo_url: 'devices/hair-devices'}
        ],
        brands: [
            {
                "manufacturer_id": "1010",
                "name": "Ola Hair",
                "en_name": "Ola Hair",
                "ar_name": "اولا هير",
                "image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/65695786_OlaHair-500x500.png",
                "background_image": "",
                "original_image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/65695786_OlaHair-500x500.png",
                "background_original_image": "",
                "status": true,
                "seo_url": "ola-hair"
            },
            {
                "manufacturer_id": "271",
                "name": "Braun",
                "en_name": "Braun",
                "ar_name": "براون",
                "image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/13794199_Braun1-500x500.png",
                "background_image": "",
                "original_image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/13794199_Braun1-500x500.png",
                "background_original_image": "",
                "status": true,
                "seo_url": "braun"
            },
            {
                "manufacturer_id": "257",
                "name": "Okema",
                "en_name": "Okema",
                "ar_name": "اوكيما",
                "image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/32595163_Okema-500x500.png",
                "background_image": "",
                "original_image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/32595163_Okema-500x500.png",
                "background_original_image": "",
                "status": true,
                "seo_url": "okema"
            },
            {
                "manufacturer_id": "1083",
                "name": "Skinlarity",
                "en_name": "Skinlarity",
                "ar_name": "سكينلاريتي",
                "image": "https://d3e7ardzpaj3y4.cloudfront.net/image/brands/51377595_1709546717_caff2cc006b45a38e325d85fffd78871-500x500.png",
                "background_image": "",
                "original_image": "https://d3e7ardzpaj3y4.cloudfront.net/image/brands/51377595_1709546717_caff2cc006b45a38e325d85fffd78871-500x500.png",
                "background_original_image": "",
                "status": true,
                "seo_url": "skinlarity"
            },
            {
                "manufacturer_id": "1115",
                "name": "Qulen",
                "en_name": "Qulen",
                "ar_name": "كيولين",
                "image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/30908334_qulen-500x500.png",
                "background_image": "",
                "original_image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/30908334_qulen-500x500.png",
                "background_original_image": "",
                "status": true,
                "seo_url": "qulen"
            },
            {
                "manufacturer_id": "662",
                "name": "Joy",
                "en_name": "Joy",
                "ar_name": "جوي",
                "image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/46557647_Joy-500x500.jpg",
                "background_image": "",
                "original_image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/46557647_Joy-500x500.jpg",
                "background_original_image": "",
                "status": true,
                "seo_url": "joy"
            }
        ]
    },
    [MENU_TYPES.PREMIUM]: {
        banners: [
            {image: '/images/categories/13.png', seo_url: 'premium/premium-care'},
            {image: '/images/categories/18.png', seo_url: 'premium/premium-perfumes'}
        ],
        brands: [
            {
                "manufacturer_id": "112",
                "name": "CHANEL",
                "en_name": "CHANEL",
                "ar_name": "شانيل",
                "image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/42670272_CHANEL1-500x500.png",
                "background_image": "",
                "original_image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/42670272_CHANEL1-500x500.png",
                "background_original_image": "",
                "status": true,
                "seo_url": "chanel"
            },
            {
                "manufacturer_id": "1378",
                "name": "Kerastase",
                "en_name": "Kerastase",
                "ar_name": "كيراستاس",
                "image": "https://d3e7ardzpaj3y4.cloudfront.net/image/brands/3869922_1709118291_e5e9651c8d1023d9c5573e9434932f51-500x500.png",
                "background_image": "",
                "original_image": "https://d3e7ardzpaj3y4.cloudfront.net/image/brands/3869922_1709118291_e5e9651c8d1023d9c5573e9434932f51-500x500.png",
                "background_original_image": "",
                "status": true,
                "seo_url": "kerastase"
            },
            {
                "manufacturer_id": "91",
                "name": "Givenchy",
                "en_name": "Givenchy",
                "ar_name": "جيفينشي",
                "image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/87479446_Givenchy1-500x500.png",
                "background_image": "",
                "original_image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/87479446_Givenchy1-500x500.png",
                "background_original_image": "",
                "status": true,
                "seo_url": "givenchy"
            },
            {
                "manufacturer_id": "5",
                "name": "MAC",
                "en_name": "MAC",
                "ar_name": "ماك",
                "image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/11811566_Mac-500x500.png",
                "background_image": "",
                "original_image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/11811566_Mac-500x500.png",
                "background_original_image": "",
                "status": true,
                "seo_url": "mac"
            },
            {
                "manufacturer_id": "60",
                "name": "Estee Lauder",
                "en_name": "Estee Lauder",
                "ar_name": "استي لودر",
                "image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/84831909_Estee Lauder1-500x500.png",
                "background_image": "",
                "original_image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/manufacturer/84831909_Estee Lauder1-500x500.png",
                "background_original_image": "",
                "status": true,
                "seo_url": "estee-lauder"
            },
            {
                "manufacturer_id": "161",
                "name": "CLINIQUE",
                "en_name": "CLINIQUE",
                "ar_name": "كلينيك",
                "image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/77396091_CLINIQUE-500x500.png",
                "background_image": "",
                "original_image": "https://d3e7ardzpaj3y4.cloudfront.net/image/catalog/77396091_CLINIQUE-500x500.png",
                "background_original_image": "",
                "status": true,
                "seo_url": "clinique"
            }
        ]
    },
    [MENU_TYPES.NAILS]: {
        banners: [
            {image: '/images/categories/3.png', seo_url: 'nails/nails-polish'},
            {image: '/images/categories/2.png', seo_url: 'nails/nails-set'}
        ],
        brands: []
    },
    [MENU_TYPES.GIFTS]: {
        banners: [
            {image: '/images/categories/5.png', seo_url: 'gifts/men-gift-sets'},
            {image: '/images/categories/1.png', seo_url: 'gifts/women-gifts-sets'},
        ],
        brands: []
    },
    [MENU_TYPES.HOME_SCENTS]: {
        banners: [
            {image: '/images/categories/8.png', seo_url: 'home-scents/oud--bakhoor'},
            {image: '/images/categories/7.png', seo_url: 'home-scents/censers'}
        ],
        brands: []
    },
    [MENU_TYPES.VITAMINS]: {
        banners: [
            {
                image: '/images/categories/10.png',
                seo_url: 'vitamins-supplements/yumearth-organic-gummy-bears-pomegranate-50g-n27109'
            },
            {
                image: '/images/categories/9.png',
                seo_url: 'care/health-care/vitamins-amp-supplements/centrum-multivitamins-with-lutein-30-tablets-n22763'
            }
        ],
        brands: []
    },
}