<template>
  <div class="flex" v-bind="$attrs" @click.stop.prevent="addToWishlist">
    <van-loading v-if="loading" size="18"/>

    <template v-if="inWishlist && !loading">
      <slot name="active">
        <van-icon :name="$publicPath('/images/cart-buttons/favourite-active.svg')"
                  class="bg-white rounded-full cursor-pointer"
                  size="22"/>
      </slot>
    </template>

    <template v-if="!inWishlist && !loading">
      <slot name="in-active">
        <van-icon :name="$publicPath('/images/cart-buttons/favourite.svg')"
                  class="bg-white rounded-full cursor-pointer"
                  size="22"/>
      </slot>
    </template>
  </div>
</template>

<script setup>
import {addWishlistMutation, removeWishlistMutation} from "@/plugins/API/cart";
import {useWishlistStore} from "@/store/wishlist";
import {track} from "@/composables/useTrackEvent";
import {useAuthStore} from "~/store/auth";
import {useEventBus} from "@vueuse/core/index";
import {EVENTS, TRACKERS} from "@/constants/events";

const authStore = useAuthStore();
const {isLoggedIn} = storeToRefs(authStore);
const {emit: emitAuth} = useEventBus(EVENTS.AUTH_DIALOG);

const props = defineProps({
  id: {
    type: [String, Number],
    required: true
  },
  product: {
    type: Object,
    default: {}
  }
})

const {isLoading: addWishlistLoading, mutateAsync: addWishlistMutateAsync} = addWishlistMutation()
const {isLoading: removeWishlistLoading, mutateAsync: removeWishlistMutateAsync} = removeWishlistMutation()
const loading = computed(() => addWishlistLoading.value || removeWishlistLoading.value)

const wishlistStore = useWishlistStore()

const inWishlist = computed(() => {

  if (!!props.product?.option_detail) {
    return wishlistStore.products?.find(
        (item) => Number(item.product_id) === Number(props.id) && Number(props.product?.option_detail?.product_option_value_id) === Number(item.option_detail?.product_option_value_id)
    )
  }

  return !!wishlistStore.products.find(item => Number(item.product_id) === Number(props.id))
})

const addToWishlist = async () => {
  if (isLoggedIn.value) {

    const {option_detail = null} = props.product
    const option = !!option_detail && {
      [option_detail?.product_option_id]: option_detail?.product_option_value_id,
    };

    if (inWishlist.value) {
      try {
        await removeWishlistMutateAsync({id: props.id, option})
        track(TRACKERS.FAVORITE_REMOVE, {product: props.product})
      } catch (_) {}

    } else {
      try {
        await addWishlistMutateAsync({id: props.id, option})
        track(TRACKERS.FAVORITE_ADD, {product: props.product})
      } catch (_) {}
    }
  } else {
    emitAuth({redirection: false})
  }
}
</script>

<style scoped>

</style>