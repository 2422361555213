import {useAuthStore} from '@/store/auth'
import {COOKIE_KEYS} from "@/constants/cookie";

export default defineNuxtRouteMiddleware((from) => {
    const token = useCookie(COOKIE_KEYS.USER_TOKEN, {secure: true})
    const authStore = useAuthStore()

    const {isLoggedIn} = storeToRefs(authStore)

    if (!isLoggedIn.value && !token.value) {
        const pendingRoute = useCookie(COOKIE_KEYS.PENDING_ROUTE, {secure: true});
        pendingRoute.value = from?.fullPath

        if (!useNuxtApp().$device.isDesktop) return navigateTo(useNuxtApp().$routerUrl('/login'))
        return navigateTo(useNuxtApp().$routerUrl('/?auth=true'))
    }

})