import {AUTH_TYPES} from "@/constants"
import {track} from "@/composables/useTrackEvent"
import {TRACKERS} from "@/constants/events";
import {COOKIE_KEYS} from "@/constants/cookie";

export const useAuthStore = defineStore("auth", () => {
    const isRateOrderPopupLoaded = ref(false)
    const isOrderFeedbackPopupLoaded = ref(false)
    const token = useCookie(COOKIE_KEYS.USER_TOKEN, {secure: true})
    const hasToken = computed(() => !!token?.value || false)
    const blockRedirection = ref(false)
    const loading = ref(false)
    const isShowCloseLabel = ref(true)

    const selectedType = ref(AUTH_TYPES.LOGIN_PHONE)
    const changeType = (type) => {
        selectedType.value = type
    }
    const resetType = () => selectedType.value = AUTH_TYPES.LOGIN_PHONE

    // const { isLoading, mutateAsync } = getUser();

    const userData = ref(null)
    const isLoggedIn = computed(() => !!userData?.value || false);
    const user = computed(() => userData?.value || {});

    const setUser = (user) => {
        user ? track(TRACKERS.LOGIN, user) : track(TRACKERS.LOGOUT)
        userData.value = user;
    }

    // Use for loggedIn protected routes to cached and redirect the user to the last route
    const pendingRedirection = ref("")

    const updateUserBalance = (balance = "") => {
        userData.value.wallet_balance_formatted = balance
    }
    const updateUserTicketNotifications = (count = "") => {
        userData.value.ticket_notifications = count
    }

    const fetchUser = () => {
        useNuxtApp()
            .$axios.get("?route=rest/account/account")
            .then((response) => {
                setUser(response?.data)
                loading.value = false
            })
    }

    if (hasToken.value && !isLoggedIn.value) {
        fetchUser()
    }

    return {
        selectedType,
        changeType,
        resetType,
        setUser,
        isLoggedIn,
        pendingRedirection,
        updateUserBalance,
        user,
        userData,
        loading,
        blockRedirection,
        updateUserTicketNotifications,
        isRateOrderPopupLoaded,
        isOrderFeedbackPopupLoaded,
        fetchUser,
        isShowCloseLabel
    };
});



