import {useAuthStore} from '@/store/auth'
import {EVENTS} from "@/constants/events";
import {AUTH_TYPES} from "@/constants"

export const useAuth = () => {

    const authStore = useAuthStore()
    const {isLoggedIn} = storeToRefs(authStore)
    const {emit: emitAuth} = useEventBus(EVENTS.AUTH_DIALOG);
    const router = useRouter()

    const useRedirection = (item) => {

        if (!isLoggedIn.value) {
            item?.data_url.endsWith("register") && authStore.changeType(AUTH_TYPES.REGISTER)
            authStore.isShowCloseLabel = false
            emitAuth({redirection: false})
            return
        }

        const parsedUrl = new URL(item?.data_url);
        router.replace(useNuxtApp().$routerUrl(parsedUrl.pathname))

    }

    return {
        useRedirection
    }
}