<template>
  <div v-if="slider?.length"
       :style="{ gridTemplateColumns: getColumns, gap: data?.margin == null ? '12px' : `${data?.margin}px` }"
       class="grid mx-3 sm:mx-0 mb-3 banner">
    <nuxt-link v-for="(item, index) in slider" :key="`grid-image-${index}`"
               :to="item.type == BANNER_TYPE_FOR_AUTH ? undefined : $routerUrl(item.seo_url)"
               @click.prevent="onBannerClick(item)">
      <niceone-image :src="item?.image_url" class="rounded-md"/>
    </nuxt-link>
  </div>
</template>

<script setup>
import {useHomeStore} from "@/store/home";
import {useAuth} from '@/composables/useAuth'
import {BANNER_TYPE_FOR_AUTH} from '@/constants'

const props = defineProps({
  data: [Object],
  loading: {
    type: Boolean,
    default: false
  }
})

const slider = computed(() => {
  return props.data.data.filter((img) => {
    if (!useNuxtApp().$device.isDesktop) return true
    if (!img.for_mobile && useNuxtApp().$device.isDesktop) return true
    return false
  })
})

const getColumns = computed(() => `repeat(${slider?.value?.length}, minmax(0, 1fr))`)

const {useRedirection} = useAuth()
const onBannerClick = (item) => {
  const type = parseInt(item?.type)
  if (type === 10) {
    const homeStore = useHomeStore()
    const {tabId} = storeToRefs(homeStore)
    tabId.value = item?.tab_id
  }

  if (type === BANNER_TYPE_FOR_AUTH) {
    useRedirection(item)
  }
}
</script>