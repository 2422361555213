<template>
  <div class="flex flex-col gap-3 w-full mb-2`">
    <nuxt-link v-for="(item ,index) in getBanners" :key="index"
               :to="item?.type == BANNER_TYPE_FOR_AUTH ? undefined : $routerUrl(item?.seo_url)"
               @click.prevent="toAuthentication(item)">
      <niceone-image :src="$publicPath(item.image)" class="rounded-md w-full"/>
    </nuxt-link>
  </div>
</template>

<script setup>
import {useAuth} from '@/composables/useAuth'
import {BANNER_TYPE_FOR_AUTH} from '@/constants'

const props = defineProps({
  data: {
    type: Object,
    default: () => {
    }
  },
  banners: {
    type: Array,
    default: () => []
  }
})
const getBanners = computed(() => props.banners?.length ? props.banners : props.data.banners)

const {useRedirection} = useAuth()
const toAuthentication = (item) => {
  const type = parseInt(item?.type)
  if (type === BANNER_TYPE_FOR_AUTH) {
    useRedirection(item)
  }
}
</script>

<style lang="scss" scoped>
</style>