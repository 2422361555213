<template>
  <div class="absolute left-0 right-0 top-0 z-1 p-4">
    <div class="flex items-start justify-between">
      <div>
        <niceone-image :src="$publicPath('/images/video/close.svg')" width="26" @click="close" v-if="!isClose"/>
        <niceone-image :src="$publicPath('/images/video/close.svg')" width="26" @click="emit('close')" v-else/>
        <div class="text-white text-lg font-bold">{{ item?.title }}</div>
        <div class="text-white font-bold text-xs" :class="[isClose ? '' :'mt-1']">{{ item?.description }}
          {{ item?.description }}
        </div>
      </div>
      <div class="flex items-center gap-1">
        <van-badge :content="count" :max="99" :position="$i18n.locale === 'en' ? 'top-right' : 'top-left'"
                   class="mx-3" color="#997adb" @click="showCart = true">
          <niceone-image :src="$publicPath('/images/video/cart.svg')" class="min-w-[18px]" width="18"/>
        </van-badge>
        <niceone-image :src="$publicPath('/images/video/share.svg')" class="min-w-[26px]" width="26"
                       @click="shareVideo"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useCartStore} from "@/store/cart";
import {useShareLink} from "@/composables/useShareLink";

const props = defineProps({
  item: {
    type: Object,
    default: () => {
    }
  },
  isPlaying: {
    type: Boolean,
    default: false
  },
  isClose: {
    type: Boolean,
    default: false
  },
})

const emit = defineEmits(['close', 'toggleVideo'])

const cartStore = useCartStore()
const {count, showCart} = storeToRefs(cartStore)
const {share} = useShareLink()

const shareVideo = () => {
  props.isPlaying && emit('toggleVideo')
  const data = {
    title: props?.item?.title,
    text: props?.item?.share_url,
  }
  share(data.text, data.title)
}

const router = useRouter()
const close = () => {
  router.push(useNuxtApp().$routerUrl('/'))
}
</script>
