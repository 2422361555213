<template>
  <slot v-if="!loading" :is-new="variant === EXPERIMENT_TYPES.TREATMENT"></slot>
</template>

<script setup>
import {ampli} from '../ampli/index';
import {EXPERIMENT_TYPES} from "@/constants";
import {Experiment} from "@amplitude/experiment-js-client";
import useCustomConfig from "~/composables/useEnvConfig";

onMounted(() => {
  init()
})

const emit = defineEmits(['loaded'])
const {flag} = defineProps({
  flag: {
    type: String,
    default: ""
  }
})

// For server we need the loader to be false by default
const loading = ref(!process.server)

const variant = ref(EXPERIMENT_TYPES.CONTROL)

const init = async () => {

  const config = useCustomConfig();

  const experiment = Experiment.initializeWithAmplitudeAnalytics(
      config.public.AMPLI_EXPERIMENT_KEY
  )

  // Get device_id from amplitude
  const device_id = ampli.client.getDeviceId()
  const user = {
    device_id
  }

  // Start the SDK and await the promise result.
  await experiment.start(user);

  // Lookup a flag's variant.
  variant.value = experiment.variant(flag)?.value;

  // Fetching is finished
  loading.value = false

  // emit data after loading all experment feature
  emit('loaded', variant.value === EXPERIMENT_TYPES.TREATMENT)
}
</script>

<style scoped>

</style>