import {TRACKERS} from "@/constants/events";
import useCustomConfig from "@/composables/useEnvConfig";
import {COOKIE_KEYS} from "@/constants/cookie";
import {useAuthStore} from "@/store/auth";

export default {
    [TRACKERS.LOGIN](user) {
        // Set authenticated user token
        window.aa("setAuthenticatedUserToken", user?.customer_id);
    },
    [TRACKERS.INITIALIZED]() {
        const config = useCustomConfig();
        window.aa('init', {
            appId: config.public.ALGOLIA_APPLICATION_ID,
            apiKey: config.public.ALGOLIA_SEARCH_API_KEY
        })

        const session = useCookie(COOKIE_KEYS.SESSION, {secure: true}).value;
        // Set anonymous user token
        window.aa("setUserToken", session);
    },
    [TRACKERS.ALGOLIA_PRODUCT_CLICK]({id, queryID = null, index = 0}) {
        const config = useCustomConfig();
        window.aa("clickedObjectIDsAfterSearch", {
            index: config.public.ALGOLIA_INDEX_NAME,
            eventName: "Product click after search",
            queryID,
            objectIDs: [id],
            positions: [index + 1],
        });
    },
    [TRACKERS.ADD_TO_CART]({product = null}) {
        const config = useCustomConfig();

        const session = useCookie(COOKIE_KEYS.SESSION, {secure: true}).value;

        const authStore = useAuthStore()
        const {userData} = storeToRefs(authStore)

        const userToken = window.aa("getUserToken") || session;
        const authenticatedUserToken = window.aa("getAuthenticatedUserToken") || userData.value?.customer_id || "";

        const formatToTwoDecimals = (value) => {
            const num = parseFloat(value) || 0.00;
            return Number(num.toFixed(2)); // Convert to float explicitly
        };

        const productPrice = formatToTwoDecimals(product?.event_price || product?.priceWithoutCurrency);
        const discount = formatToTwoDecimals(product?.special?.length ? product.special[0].originalPriceWithoutCurrency - product.special[0].priceWithoutCurrency : 0);

        window.aa("addedToCartObjectIDs", {
            index: config.public.ALGOLIA_INDEX_NAME,
            eventName: "Products Added to Cart",
            eventType: "conversion",
            eventSubtype: "addToCart",
            objectIDs: [product.id],
            objectData: [{
                price: productPrice,
                discount: discount,
                quantity: 1
            }],
            value: productPrice,
            currency: "SAR",
            userToken: userToken,
            authenticatedUserToken: authenticatedUserToken,
            timestamp: new Date().getTime()
        })
    },
    [TRACKERS.ORDER_SUCCESS](data) {
        const config = useCustomConfig();

        const session = useCookie(COOKIE_KEYS.SESSION, {secure: true}).value;

        const authStore = useAuthStore()
        const {userData} = storeToRefs(authStore)

        const userToken = window.aa("getUserToken") || session;
        const authenticatedUserToken = window.aa("getAuthenticatedUserToken") || userData.value?.customer_id || "";

        const objectIDs = data.products.map(item => item.product_id)

        window.aa("purchasedObjectIDs", {
            index: config.public.ALGOLIA_INDEX_NAME,
            eventName: "Products Purchased",
            eventType: "conversion",
            eventSubtype: "purchase",
            objectIDs,
            objectData: data.products.map((product) => {
                return {
                    id: product.product_id,
                    quantity: 1,
                    price: product?.event_price || 0.0
                }
            }),
            value: Number(data.total) || 0,
            currency: "SAR",
            userToken: userToken,
            authenticatedUserToken: authenticatedUserToken,
            timestamp: new Date().getTime()
        })
    },
    [TRACKERS.ALGOLIA_ADD_TO_CART]({id = null, queryID = null} = null) {
        const config = useCustomConfig();

        const session = useCookie(COOKIE_KEYS.SESSION, {secure: true}).value;

        const authStore = useAuthStore()
        const {userData} = storeToRefs(authStore)

        const userToken = window.aa("getUserToken") || session;
        const authenticatedUserToken = window.aa("getAuthenticatedUserToken") || userData.value?.customer_id || "";

        window.aa("convertedObjectIDsAfterSearch", {
            index: config.public.ALGOLIA_INDEX_NAME,
            eventName: 'Product Converted',
            objectIDs: [id],
            queryID,
            userToken: userToken,
            authenticatedUserToken: authenticatedUserToken,
        })
    },
}
