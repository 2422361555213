import {CURRENCY_CODES} from "@/helper";
import {COOKIE_KEYS} from "~/constants/cookie";

export const useCurrencyStore = defineStore('currency', () => {

    const _currency = useCookie(COOKIE_KEYS.CURRENCY, {secure: true})
    const currency = computed({
        get() {
            return _currency.value || CURRENCY_CODES.SAR
        },
        set(code) {
            _currency.value = code
        }
    })

    const currencies = ref([])
    const setCurrencies = (cbCurrencies) => {
        currencies.value = cbCurrencies
    }

    return {
        currency,
        currencies,
        setCurrencies
    }
})