import { default as indexxaYTHHZPphMeta } from "/home/circleci/app/pages/index.vue?macro=true";
import { default as indexNVnE9bEZpZMeta } from "/home/circleci/app/pages/[...slugs]/index.vue?macro=true";
import { default as indexSAcmSALnDwMeta } from "/home/circleci/app/pages/account/about-niceone/index.vue?macro=true";
import { default as indexLUoAC57VmsMeta } from "/home/circleci/app/pages/account/country-and-language/index.vue?macro=true";
import { default as index6rQmxcFl76Meta } from "/home/circleci/app/pages/account/help-and-support/index.vue?macro=true";
import { default as indexU5SUDwPrRAMeta } from "/home/circleci/app/pages/account/index.vue?macro=true";
import { default as index6cauuJUcI8Meta } from "/home/circleci/app/pages/addresses/index.vue?macro=true";
import { default as indexylMkKt1yfZMeta } from "/home/circleci/app/pages/blogs/details/index.vue?macro=true";
import { default as indexrV82Pl3rfGMeta } from "/home/circleci/app/pages/blogs/index.vue?macro=true";
import { default as indexypVFnYOoX2Meta } from "/home/circleci/app/pages/brands/index.vue?macro=true";
import { default as indexW8OJ2xcWVRMeta } from "/home/circleci/app/pages/cart/index.vue?macro=true";
import { default as indexAZcU42jMbgMeta } from "/home/circleci/app/pages/categories/index.vue?macro=true";
import { default as _91_91id_93_93mvc4rrgPrjMeta } from "/home/circleci/app/pages/checkout/address/[[id]].vue?macro=true";
import { default as index0Y5B1McseRMeta } from "/home/circleci/app/pages/checkout/address/list/index.vue?macro=true";
import { default as indexAUgR63KFfnMeta } from "/home/circleci/app/pages/checkout/index.vue?macro=true";
import { default as index18quSPLyBBMeta } from "/home/circleci/app/pages/confirm-account-delete/[id]/index.vue?macro=true";
import { default as index5MyznH7l5nMeta } from "/home/circleci/app/pages/debug/index.vue?macro=true";
import { default as index0kRHLmnMt5Meta } from "/home/circleci/app/pages/favourite/index.vue?macro=true";
import { default as _91_91id_93_93bvgLSryfZCMeta } from "/home/circleci/app/pages/feedback/[[id]].vue?macro=true";
import { default as indexDTIR05oixZMeta } from "/home/circleci/app/pages/gift-test/[[id]]/index.vue?macro=true";
import { default as indexbpcrXDHbWfMeta } from "/home/circleci/app/pages/gift/[[id]]/index.vue?macro=true";
import { default as indexKh7yZcF5ZFMeta } from "/home/circleci/app/pages/invoice/[id]/index.vue?macro=true";
import { default as indexfJkU31nocWMeta } from "/home/circleci/app/pages/login/index.vue?macro=true";
import { default as indexgUrCE5OgHQMeta } from "/home/circleci/app/pages/loyalty/details/index.vue?macro=true";
import { default as indexwhPLkm6XCeMeta } from "/home/circleci/app/pages/loyalty/index.vue?macro=true";
import { default as indexnvuyE7wmKuMeta } from "/home/circleci/app/pages/loyalty/terms/index.vue?macro=true";
import { default as indexHc6virxXUxMeta } from "/home/circleci/app/pages/notifications/index.vue?macro=true";
import { default as indexUUcVJD05x2Meta } from "/home/circleci/app/pages/orders/[id]/index.vue?macro=true";
import { default as indexyOpshqA3sbMeta } from "/home/circleci/app/pages/orders/index.vue?macro=true";
import { default as index6dm47pHmd3Meta } from "/home/circleci/app/pages/password/index.vue?macro=true";
import { default as index5xtAMktxXCMeta } from "/home/circleci/app/pages/privacy/index.vue?macro=true";
import { default as indexbqrISPSquaMeta } from "/home/circleci/app/pages/product/index.vue?macro=true";
import { default as index78gaKrnwp8Meta } from "/home/circleci/app/pages/profile/index.vue?macro=true";
import { default as indexaYT6T4U9TtMeta } from "/home/circleci/app/pages/referral/index.vue?macro=true";
import { default as indexbwUeIYYri5Meta } from "/home/circleci/app/pages/refund/index.vue?macro=true";
import { default as indexPChMWttK5VMeta } from "/home/circleci/app/pages/register/index.vue?macro=true";
import { default as indexTpKOICGjV2Meta } from "/home/circleci/app/pages/reset/index.vue?macro=true";
import { default as index8BU9ht3N8sMeta } from "/home/circleci/app/pages/reward/about/index.vue?macro=true";
import { default as indexMbY3u8BDVaMeta } from "/home/circleci/app/pages/reward/banner/index.vue?macro=true";
import { default as indexEMlrlLxNPyMeta } from "/home/circleci/app/pages/reward/details/index.vue?macro=true";
import { default as indexQyQSKNiz6nMeta } from "/home/circleci/app/pages/reward/history/index.vue?macro=true";
import { default as indexH5QALV0TphMeta } from "/home/circleci/app/pages/reward/index.vue?macro=true";
import { default as indexEENXWQEUD0Meta } from "/home/circleci/app/pages/reward/terms/index.vue?macro=true";
import { default as indexfWvQb0noKiMeta } from "/home/circleci/app/pages/share_cart/[id]/index.vue?macro=true";
import { default as indexW3jyGsCOwuMeta } from "/home/circleci/app/pages/success/[id]/index.vue?macro=true";
import { default as indexYMns3cSdyaMeta } from "/home/circleci/app/pages/terms/index.vue?macro=true";
import { default as indexBTX4e1g4tzMeta } from "/home/circleci/app/pages/tickets/[id]/index.vue?macro=true";
import { default as indexMoQC1AZrdcMeta } from "/home/circleci/app/pages/tickets/index.vue?macro=true";
import { default as indexmKw3gwvGzEMeta } from "/home/circleci/app/pages/track/index.vue?macro=true";
import { default as indexMEXO7RDDiPMeta } from "/home/circleci/app/pages/videos/[id]/index.vue?macro=true";
import { default as indexVJHcnakILCMeta } from "/home/circleci/app/pages/videos/index.vue?macro=true";
import { default as indexbTxzeXEkqHMeta } from "/home/circleci/app/pages/voucher/[id]/index.vue?macro=true";
import { default as indexHP0TIXDrBeMeta } from "/home/circleci/app/pages/voucher/checkout/index.vue?macro=true";
import { default as indexNpU8sKAnr4Meta } from "/home/circleci/app/pages/voucher/index.vue?macro=true";
import { default as indexeedUBFpVyEMeta } from "/home/circleci/app/pages/voucher/success/[id]/index.vue?macro=true";
import { default as indexyUVzshJU8GMeta } from "/home/circleci/app/pages/wallet/index.vue?macro=true";
export default [
  {
    name: indexxaYTHHZPphMeta?.name ?? "index",
    path: indexxaYTHHZPphMeta?.path ?? "/",
    meta: indexxaYTHHZPphMeta || {},
    alias: indexxaYTHHZPphMeta?.alias || [],
    redirect: indexxaYTHHZPphMeta?.redirect,
    component: () => import("/home/circleci/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexNVnE9bEZpZMeta?.name ?? "slugs___ar",
    path: indexNVnE9bEZpZMeta?.path ?? "/ar/:slugs(.*)*",
    meta: indexNVnE9bEZpZMeta || {},
    alias: indexNVnE9bEZpZMeta?.alias || [],
    redirect: indexNVnE9bEZpZMeta?.redirect,
    component: () => import("/home/circleci/app/pages/[...slugs]/index.vue").then(m => m.default || m)
  },
  {
    name: indexNVnE9bEZpZMeta?.name ?? "slugs___en",
    path: indexNVnE9bEZpZMeta?.path ?? "/en/:slugs(.*)*",
    meta: indexNVnE9bEZpZMeta || {},
    alias: indexNVnE9bEZpZMeta?.alias || [],
    redirect: indexNVnE9bEZpZMeta?.redirect,
    component: () => import("/home/circleci/app/pages/[...slugs]/index.vue").then(m => m.default || m)
  },
  {
    name: indexSAcmSALnDwMeta?.name ?? "account-about-niceone___ar",
    path: indexSAcmSALnDwMeta?.path ?? "/ar/account/about-niceone",
    meta: indexSAcmSALnDwMeta || {},
    alias: indexSAcmSALnDwMeta?.alias || [],
    redirect: indexSAcmSALnDwMeta?.redirect,
    component: () => import("/home/circleci/app/pages/account/about-niceone/index.vue").then(m => m.default || m)
  },
  {
    name: indexSAcmSALnDwMeta?.name ?? "account-about-niceone___en",
    path: indexSAcmSALnDwMeta?.path ?? "/en/account/about-niceone",
    meta: indexSAcmSALnDwMeta || {},
    alias: indexSAcmSALnDwMeta?.alias || [],
    redirect: indexSAcmSALnDwMeta?.redirect,
    component: () => import("/home/circleci/app/pages/account/about-niceone/index.vue").then(m => m.default || m)
  },
  {
    name: indexLUoAC57VmsMeta?.name ?? "account-country-and-language___ar",
    path: indexLUoAC57VmsMeta?.path ?? "/ar/account/country-and-language",
    meta: indexLUoAC57VmsMeta || {},
    alias: indexLUoAC57VmsMeta?.alias || [],
    redirect: indexLUoAC57VmsMeta?.redirect,
    component: () => import("/home/circleci/app/pages/account/country-and-language/index.vue").then(m => m.default || m)
  },
  {
    name: indexLUoAC57VmsMeta?.name ?? "account-country-and-language___en",
    path: indexLUoAC57VmsMeta?.path ?? "/en/account/country-and-language",
    meta: indexLUoAC57VmsMeta || {},
    alias: indexLUoAC57VmsMeta?.alias || [],
    redirect: indexLUoAC57VmsMeta?.redirect,
    component: () => import("/home/circleci/app/pages/account/country-and-language/index.vue").then(m => m.default || m)
  },
  {
    name: index6rQmxcFl76Meta?.name ?? "account-help-and-support___ar",
    path: index6rQmxcFl76Meta?.path ?? "/ar/account/help-and-support",
    meta: index6rQmxcFl76Meta || {},
    alias: index6rQmxcFl76Meta?.alias || [],
    redirect: index6rQmxcFl76Meta?.redirect,
    component: () => import("/home/circleci/app/pages/account/help-and-support/index.vue").then(m => m.default || m)
  },
  {
    name: index6rQmxcFl76Meta?.name ?? "account-help-and-support___en",
    path: index6rQmxcFl76Meta?.path ?? "/en/account/help-and-support",
    meta: index6rQmxcFl76Meta || {},
    alias: index6rQmxcFl76Meta?.alias || [],
    redirect: index6rQmxcFl76Meta?.redirect,
    component: () => import("/home/circleci/app/pages/account/help-and-support/index.vue").then(m => m.default || m)
  },
  {
    name: indexU5SUDwPrRAMeta?.name ?? "account___ar",
    path: indexU5SUDwPrRAMeta?.path ?? "/ar/account",
    meta: indexU5SUDwPrRAMeta || {},
    alias: indexU5SUDwPrRAMeta?.alias || [],
    redirect: indexU5SUDwPrRAMeta?.redirect,
    component: () => import("/home/circleci/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexU5SUDwPrRAMeta?.name ?? "account___en",
    path: indexU5SUDwPrRAMeta?.path ?? "/en/account",
    meta: indexU5SUDwPrRAMeta || {},
    alias: indexU5SUDwPrRAMeta?.alias || [],
    redirect: indexU5SUDwPrRAMeta?.redirect,
    component: () => import("/home/circleci/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: index6cauuJUcI8Meta?.name ?? "addresses___ar",
    path: index6cauuJUcI8Meta?.path ?? "/ar/addresses",
    meta: index6cauuJUcI8Meta || {},
    alias: index6cauuJUcI8Meta?.alias || [],
    redirect: index6cauuJUcI8Meta?.redirect,
    component: () => import("/home/circleci/app/pages/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: index6cauuJUcI8Meta?.name ?? "addresses___en",
    path: index6cauuJUcI8Meta?.path ?? "/en/addresses",
    meta: index6cauuJUcI8Meta || {},
    alias: index6cauuJUcI8Meta?.alias || [],
    redirect: index6cauuJUcI8Meta?.redirect,
    component: () => import("/home/circleci/app/pages/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: indexylMkKt1yfZMeta?.name ?? "blogs-details___ar",
    path: indexylMkKt1yfZMeta?.path ?? "/ar/blogs/details",
    meta: indexylMkKt1yfZMeta || {},
    alias: indexylMkKt1yfZMeta?.alias || [],
    redirect: indexylMkKt1yfZMeta?.redirect,
    component: () => import("/home/circleci/app/pages/blogs/details/index.vue").then(m => m.default || m)
  },
  {
    name: indexylMkKt1yfZMeta?.name ?? "blogs-details___en",
    path: indexylMkKt1yfZMeta?.path ?? "/en/blogs/details",
    meta: indexylMkKt1yfZMeta || {},
    alias: indexylMkKt1yfZMeta?.alias || [],
    redirect: indexylMkKt1yfZMeta?.redirect,
    component: () => import("/home/circleci/app/pages/blogs/details/index.vue").then(m => m.default || m)
  },
  {
    name: indexrV82Pl3rfGMeta?.name ?? "blogs___ar",
    path: indexrV82Pl3rfGMeta?.path ?? "/ar/blogs",
    meta: indexrV82Pl3rfGMeta || {},
    alias: indexrV82Pl3rfGMeta?.alias || [],
    redirect: indexrV82Pl3rfGMeta?.redirect,
    component: () => import("/home/circleci/app/pages/blogs/index.vue").then(m => m.default || m)
  },
  {
    name: indexrV82Pl3rfGMeta?.name ?? "blogs___en",
    path: indexrV82Pl3rfGMeta?.path ?? "/en/blogs",
    meta: indexrV82Pl3rfGMeta || {},
    alias: indexrV82Pl3rfGMeta?.alias || [],
    redirect: indexrV82Pl3rfGMeta?.redirect,
    component: () => import("/home/circleci/app/pages/blogs/index.vue").then(m => m.default || m)
  },
  {
    name: indexypVFnYOoX2Meta?.name ?? "brands___ar",
    path: indexypVFnYOoX2Meta?.path ?? "/ar/brands",
    meta: indexypVFnYOoX2Meta || {},
    alias: indexypVFnYOoX2Meta?.alias || [],
    redirect: indexypVFnYOoX2Meta?.redirect,
    component: () => import("/home/circleci/app/pages/brands/index.vue").then(m => m.default || m)
  },
  {
    name: indexypVFnYOoX2Meta?.name ?? "brands___en",
    path: indexypVFnYOoX2Meta?.path ?? "/en/brands",
    meta: indexypVFnYOoX2Meta || {},
    alias: indexypVFnYOoX2Meta?.alias || [],
    redirect: indexypVFnYOoX2Meta?.redirect,
    component: () => import("/home/circleci/app/pages/brands/index.vue").then(m => m.default || m)
  },
  {
    name: indexW8OJ2xcWVRMeta?.name ?? "cart___ar",
    path: indexW8OJ2xcWVRMeta?.path ?? "/ar/cart",
    meta: indexW8OJ2xcWVRMeta || {},
    alias: indexW8OJ2xcWVRMeta?.alias || [],
    redirect: indexW8OJ2xcWVRMeta?.redirect,
    component: () => import("/home/circleci/app/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexW8OJ2xcWVRMeta?.name ?? "cart___en",
    path: indexW8OJ2xcWVRMeta?.path ?? "/en/cart",
    meta: indexW8OJ2xcWVRMeta || {},
    alias: indexW8OJ2xcWVRMeta?.alias || [],
    redirect: indexW8OJ2xcWVRMeta?.redirect,
    component: () => import("/home/circleci/app/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexAZcU42jMbgMeta?.name ?? "categories___ar",
    path: indexAZcU42jMbgMeta?.path ?? "/ar/categories",
    meta: indexAZcU42jMbgMeta || {},
    alias: indexAZcU42jMbgMeta?.alias || [],
    redirect: indexAZcU42jMbgMeta?.redirect,
    component: () => import("/home/circleci/app/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexAZcU42jMbgMeta?.name ?? "categories___en",
    path: indexAZcU42jMbgMeta?.path ?? "/en/categories",
    meta: indexAZcU42jMbgMeta || {},
    alias: indexAZcU42jMbgMeta?.alias || [],
    redirect: indexAZcU42jMbgMeta?.redirect,
    component: () => import("/home/circleci/app/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91id_93_93mvc4rrgPrjMeta?.name ?? "checkout-address-id___ar",
    path: _91_91id_93_93mvc4rrgPrjMeta?.path ?? "/ar/checkout/address/:id?",
    meta: _91_91id_93_93mvc4rrgPrjMeta || {},
    alias: _91_91id_93_93mvc4rrgPrjMeta?.alias || [],
    redirect: _91_91id_93_93mvc4rrgPrjMeta?.redirect,
    component: () => import("/home/circleci/app/pages/checkout/address/[[id]].vue").then(m => m.default || m)
  },
  {
    name: _91_91id_93_93mvc4rrgPrjMeta?.name ?? "checkout-address-id___en",
    path: _91_91id_93_93mvc4rrgPrjMeta?.path ?? "/en/checkout/address/:id?",
    meta: _91_91id_93_93mvc4rrgPrjMeta || {},
    alias: _91_91id_93_93mvc4rrgPrjMeta?.alias || [],
    redirect: _91_91id_93_93mvc4rrgPrjMeta?.redirect,
    component: () => import("/home/circleci/app/pages/checkout/address/[[id]].vue").then(m => m.default || m)
  },
  {
    name: index0Y5B1McseRMeta?.name ?? "checkout-address-list___ar",
    path: index0Y5B1McseRMeta?.path ?? "/ar/checkout/address/list",
    meta: index0Y5B1McseRMeta || {},
    alias: index0Y5B1McseRMeta?.alias || [],
    redirect: index0Y5B1McseRMeta?.redirect,
    component: () => import("/home/circleci/app/pages/checkout/address/list/index.vue").then(m => m.default || m)
  },
  {
    name: index0Y5B1McseRMeta?.name ?? "checkout-address-list___en",
    path: index0Y5B1McseRMeta?.path ?? "/en/checkout/address/list",
    meta: index0Y5B1McseRMeta || {},
    alias: index0Y5B1McseRMeta?.alias || [],
    redirect: index0Y5B1McseRMeta?.redirect,
    component: () => import("/home/circleci/app/pages/checkout/address/list/index.vue").then(m => m.default || m)
  },
  {
    name: indexAUgR63KFfnMeta?.name ?? "checkout___ar",
    path: indexAUgR63KFfnMeta?.path ?? "/ar/checkout",
    meta: indexAUgR63KFfnMeta || {},
    alias: indexAUgR63KFfnMeta?.alias || [],
    redirect: indexAUgR63KFfnMeta?.redirect,
    component: () => import("/home/circleci/app/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexAUgR63KFfnMeta?.name ?? "checkout___en",
    path: indexAUgR63KFfnMeta?.path ?? "/en/checkout",
    meta: indexAUgR63KFfnMeta || {},
    alias: indexAUgR63KFfnMeta?.alias || [],
    redirect: indexAUgR63KFfnMeta?.redirect,
    component: () => import("/home/circleci/app/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: index18quSPLyBBMeta?.name ?? "confirm-account-delete-id___ar",
    path: index18quSPLyBBMeta?.path ?? "/ar/confirm-account-delete/:id()",
    meta: index18quSPLyBBMeta || {},
    alias: index18quSPLyBBMeta?.alias || [],
    redirect: index18quSPLyBBMeta?.redirect,
    component: () => import("/home/circleci/app/pages/confirm-account-delete/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index18quSPLyBBMeta?.name ?? "confirm-account-delete-id___en",
    path: index18quSPLyBBMeta?.path ?? "/en/confirm-account-delete/:id()",
    meta: index18quSPLyBBMeta || {},
    alias: index18quSPLyBBMeta?.alias || [],
    redirect: index18quSPLyBBMeta?.redirect,
    component: () => import("/home/circleci/app/pages/confirm-account-delete/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index5MyznH7l5nMeta?.name ?? "debug___ar",
    path: index5MyznH7l5nMeta?.path ?? "/ar/debug",
    meta: index5MyznH7l5nMeta || {},
    alias: index5MyznH7l5nMeta?.alias || [],
    redirect: index5MyznH7l5nMeta?.redirect,
    component: () => import("/home/circleci/app/pages/debug/index.vue").then(m => m.default || m)
  },
  {
    name: index5MyznH7l5nMeta?.name ?? "debug___en",
    path: index5MyznH7l5nMeta?.path ?? "/en/debug",
    meta: index5MyznH7l5nMeta || {},
    alias: index5MyznH7l5nMeta?.alias || [],
    redirect: index5MyznH7l5nMeta?.redirect,
    component: () => import("/home/circleci/app/pages/debug/index.vue").then(m => m.default || m)
  },
  {
    name: index0kRHLmnMt5Meta?.name ?? "favourite___ar",
    path: index0kRHLmnMt5Meta?.path ?? "/ar/favourite",
    meta: index0kRHLmnMt5Meta || {},
    alias: index0kRHLmnMt5Meta?.alias || [],
    redirect: index0kRHLmnMt5Meta?.redirect,
    component: () => import("/home/circleci/app/pages/favourite/index.vue").then(m => m.default || m)
  },
  {
    name: index0kRHLmnMt5Meta?.name ?? "favourite___en",
    path: index0kRHLmnMt5Meta?.path ?? "/en/favourite",
    meta: index0kRHLmnMt5Meta || {},
    alias: index0kRHLmnMt5Meta?.alias || [],
    redirect: index0kRHLmnMt5Meta?.redirect,
    component: () => import("/home/circleci/app/pages/favourite/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91id_93_93bvgLSryfZCMeta?.name ?? "feedback-id___ar",
    path: _91_91id_93_93bvgLSryfZCMeta?.path ?? "/ar/feedback/:id?",
    meta: _91_91id_93_93bvgLSryfZCMeta || {},
    alias: _91_91id_93_93bvgLSryfZCMeta?.alias || [],
    redirect: _91_91id_93_93bvgLSryfZCMeta?.redirect,
    component: () => import("/home/circleci/app/pages/feedback/[[id]].vue").then(m => m.default || m)
  },
  {
    name: _91_91id_93_93bvgLSryfZCMeta?.name ?? "feedback-id___en",
    path: _91_91id_93_93bvgLSryfZCMeta?.path ?? "/en/feedback/:id?",
    meta: _91_91id_93_93bvgLSryfZCMeta || {},
    alias: _91_91id_93_93bvgLSryfZCMeta?.alias || [],
    redirect: _91_91id_93_93bvgLSryfZCMeta?.redirect,
    component: () => import("/home/circleci/app/pages/feedback/[[id]].vue").then(m => m.default || m)
  },
  {
    name: indexDTIR05oixZMeta?.name ?? "gift-test-id___ar",
    path: indexDTIR05oixZMeta?.path ?? "/ar/gift-test/:id?",
    meta: indexDTIR05oixZMeta || {},
    alias: indexDTIR05oixZMeta?.alias || [],
    redirect: indexDTIR05oixZMeta?.redirect,
    component: () => import("/home/circleci/app/pages/gift-test/[[id]]/index.vue").then(m => m.default || m)
  },
  {
    name: indexDTIR05oixZMeta?.name ?? "gift-test-id___en",
    path: indexDTIR05oixZMeta?.path ?? "/en/gift-test/:id?",
    meta: indexDTIR05oixZMeta || {},
    alias: indexDTIR05oixZMeta?.alias || [],
    redirect: indexDTIR05oixZMeta?.redirect,
    component: () => import("/home/circleci/app/pages/gift-test/[[id]]/index.vue").then(m => m.default || m)
  },
  {
    name: indexbpcrXDHbWfMeta?.name ?? "gift-id___ar",
    path: indexbpcrXDHbWfMeta?.path ?? "/ar/gift/:id?",
    meta: indexbpcrXDHbWfMeta || {},
    alias: indexbpcrXDHbWfMeta?.alias || [],
    redirect: indexbpcrXDHbWfMeta?.redirect,
    component: () => import("/home/circleci/app/pages/gift/[[id]]/index.vue").then(m => m.default || m)
  },
  {
    name: indexbpcrXDHbWfMeta?.name ?? "gift-id___en",
    path: indexbpcrXDHbWfMeta?.path ?? "/en/gift/:id?",
    meta: indexbpcrXDHbWfMeta || {},
    alias: indexbpcrXDHbWfMeta?.alias || [],
    redirect: indexbpcrXDHbWfMeta?.redirect,
    component: () => import("/home/circleci/app/pages/gift/[[id]]/index.vue").then(m => m.default || m)
  },
  {
    name: indexxaYTHHZPphMeta?.name ?? "index___ar",
    path: indexxaYTHHZPphMeta?.path ?? "/ar",
    meta: indexxaYTHHZPphMeta || {},
    alias: indexxaYTHHZPphMeta?.alias || [],
    redirect: indexxaYTHHZPphMeta?.redirect,
    component: () => import("/home/circleci/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexxaYTHHZPphMeta?.name ?? "index___en",
    path: indexxaYTHHZPphMeta?.path ?? "/en",
    meta: indexxaYTHHZPphMeta || {},
    alias: indexxaYTHHZPphMeta?.alias || [],
    redirect: indexxaYTHHZPphMeta?.redirect,
    component: () => import("/home/circleci/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexKh7yZcF5ZFMeta?.name ?? "invoice-id___ar",
    path: indexKh7yZcF5ZFMeta?.path ?? "/ar/invoice/:id()",
    meta: indexKh7yZcF5ZFMeta || {},
    alias: indexKh7yZcF5ZFMeta?.alias || [],
    redirect: indexKh7yZcF5ZFMeta?.redirect,
    component: () => import("/home/circleci/app/pages/invoice/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexKh7yZcF5ZFMeta?.name ?? "invoice-id___en",
    path: indexKh7yZcF5ZFMeta?.path ?? "/en/invoice/:id()",
    meta: indexKh7yZcF5ZFMeta || {},
    alias: indexKh7yZcF5ZFMeta?.alias || [],
    redirect: indexKh7yZcF5ZFMeta?.redirect,
    component: () => import("/home/circleci/app/pages/invoice/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfJkU31nocWMeta?.name ?? "login___ar",
    path: indexfJkU31nocWMeta?.path ?? "/ar/login",
    meta: indexfJkU31nocWMeta || {},
    alias: indexfJkU31nocWMeta?.alias || [],
    redirect: indexfJkU31nocWMeta?.redirect,
    component: () => import("/home/circleci/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexfJkU31nocWMeta?.name ?? "login___en",
    path: indexfJkU31nocWMeta?.path ?? "/en/login",
    meta: indexfJkU31nocWMeta || {},
    alias: indexfJkU31nocWMeta?.alias || [],
    redirect: indexfJkU31nocWMeta?.redirect,
    component: () => import("/home/circleci/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexgUrCE5OgHQMeta?.name ?? "loyalty-details___ar",
    path: indexgUrCE5OgHQMeta?.path ?? "/ar/loyalty/details",
    meta: indexgUrCE5OgHQMeta || {},
    alias: indexgUrCE5OgHQMeta?.alias || [],
    redirect: indexgUrCE5OgHQMeta?.redirect,
    component: () => import("/home/circleci/app/pages/loyalty/details/index.vue").then(m => m.default || m)
  },
  {
    name: indexgUrCE5OgHQMeta?.name ?? "loyalty-details___en",
    path: indexgUrCE5OgHQMeta?.path ?? "/en/loyalty/details",
    meta: indexgUrCE5OgHQMeta || {},
    alias: indexgUrCE5OgHQMeta?.alias || [],
    redirect: indexgUrCE5OgHQMeta?.redirect,
    component: () => import("/home/circleci/app/pages/loyalty/details/index.vue").then(m => m.default || m)
  },
  {
    name: indexwhPLkm6XCeMeta?.name ?? "loyalty___ar",
    path: indexwhPLkm6XCeMeta?.path ?? "/ar/loyalty",
    meta: indexwhPLkm6XCeMeta || {},
    alias: indexwhPLkm6XCeMeta?.alias || [],
    redirect: indexwhPLkm6XCeMeta?.redirect,
    component: () => import("/home/circleci/app/pages/loyalty/index.vue").then(m => m.default || m)
  },
  {
    name: indexwhPLkm6XCeMeta?.name ?? "loyalty___en",
    path: indexwhPLkm6XCeMeta?.path ?? "/en/loyalty",
    meta: indexwhPLkm6XCeMeta || {},
    alias: indexwhPLkm6XCeMeta?.alias || [],
    redirect: indexwhPLkm6XCeMeta?.redirect,
    component: () => import("/home/circleci/app/pages/loyalty/index.vue").then(m => m.default || m)
  },
  {
    name: indexnvuyE7wmKuMeta?.name ?? "loyalty-terms___ar",
    path: indexnvuyE7wmKuMeta?.path ?? "/ar/loyalty/terms",
    meta: indexnvuyE7wmKuMeta || {},
    alias: indexnvuyE7wmKuMeta?.alias || [],
    redirect: indexnvuyE7wmKuMeta?.redirect,
    component: () => import("/home/circleci/app/pages/loyalty/terms/index.vue").then(m => m.default || m)
  },
  {
    name: indexnvuyE7wmKuMeta?.name ?? "loyalty-terms___en",
    path: indexnvuyE7wmKuMeta?.path ?? "/en/loyalty/terms",
    meta: indexnvuyE7wmKuMeta || {},
    alias: indexnvuyE7wmKuMeta?.alias || [],
    redirect: indexnvuyE7wmKuMeta?.redirect,
    component: () => import("/home/circleci/app/pages/loyalty/terms/index.vue").then(m => m.default || m)
  },
  {
    name: indexHc6virxXUxMeta?.name ?? "notifications___ar",
    path: indexHc6virxXUxMeta?.path ?? "/ar/notifications",
    meta: indexHc6virxXUxMeta || {},
    alias: indexHc6virxXUxMeta?.alias || [],
    redirect: indexHc6virxXUxMeta?.redirect,
    component: () => import("/home/circleci/app/pages/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: indexHc6virxXUxMeta?.name ?? "notifications___en",
    path: indexHc6virxXUxMeta?.path ?? "/en/notifications",
    meta: indexHc6virxXUxMeta || {},
    alias: indexHc6virxXUxMeta?.alias || [],
    redirect: indexHc6virxXUxMeta?.redirect,
    component: () => import("/home/circleci/app/pages/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: indexUUcVJD05x2Meta?.name ?? "orders-id___ar",
    path: indexUUcVJD05x2Meta?.path ?? "/ar/orders/:id()",
    meta: indexUUcVJD05x2Meta || {},
    alias: indexUUcVJD05x2Meta?.alias || [],
    redirect: indexUUcVJD05x2Meta?.redirect,
    component: () => import("/home/circleci/app/pages/orders/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexUUcVJD05x2Meta?.name ?? "orders-id___en",
    path: indexUUcVJD05x2Meta?.path ?? "/en/orders/:id()",
    meta: indexUUcVJD05x2Meta || {},
    alias: indexUUcVJD05x2Meta?.alias || [],
    redirect: indexUUcVJD05x2Meta?.redirect,
    component: () => import("/home/circleci/app/pages/orders/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexyOpshqA3sbMeta?.name ?? "orders___ar",
    path: indexyOpshqA3sbMeta?.path ?? "/ar/orders",
    meta: indexyOpshqA3sbMeta || {},
    alias: indexyOpshqA3sbMeta?.alias || [],
    redirect: indexyOpshqA3sbMeta?.redirect,
    component: () => import("/home/circleci/app/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexyOpshqA3sbMeta?.name ?? "orders___en",
    path: indexyOpshqA3sbMeta?.path ?? "/en/orders",
    meta: indexyOpshqA3sbMeta || {},
    alias: indexyOpshqA3sbMeta?.alias || [],
    redirect: indexyOpshqA3sbMeta?.redirect,
    component: () => import("/home/circleci/app/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: index6dm47pHmd3Meta?.name ?? "password___ar",
    path: index6dm47pHmd3Meta?.path ?? "/ar/password",
    meta: index6dm47pHmd3Meta || {},
    alias: index6dm47pHmd3Meta?.alias || [],
    redirect: index6dm47pHmd3Meta?.redirect,
    component: () => import("/home/circleci/app/pages/password/index.vue").then(m => m.default || m)
  },
  {
    name: index6dm47pHmd3Meta?.name ?? "password___en",
    path: index6dm47pHmd3Meta?.path ?? "/en/password",
    meta: index6dm47pHmd3Meta || {},
    alias: index6dm47pHmd3Meta?.alias || [],
    redirect: index6dm47pHmd3Meta?.redirect,
    component: () => import("/home/circleci/app/pages/password/index.vue").then(m => m.default || m)
  },
  {
    name: index5xtAMktxXCMeta?.name ?? "privacy___ar",
    path: index5xtAMktxXCMeta?.path ?? "/ar/privacy",
    meta: index5xtAMktxXCMeta || {},
    alias: index5xtAMktxXCMeta?.alias || [],
    redirect: index5xtAMktxXCMeta?.redirect,
    component: () => import("/home/circleci/app/pages/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: index5xtAMktxXCMeta?.name ?? "privacy___en",
    path: index5xtAMktxXCMeta?.path ?? "/en/privacy",
    meta: index5xtAMktxXCMeta || {},
    alias: index5xtAMktxXCMeta?.alias || [],
    redirect: index5xtAMktxXCMeta?.redirect,
    component: () => import("/home/circleci/app/pages/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexbqrISPSquaMeta?.name ?? "product___ar",
    path: indexbqrISPSquaMeta?.path ?? "/ar/product",
    meta: indexbqrISPSquaMeta || {},
    alias: indexbqrISPSquaMeta?.alias || [],
    redirect: indexbqrISPSquaMeta?.redirect,
    component: () => import("/home/circleci/app/pages/product/index.vue").then(m => m.default || m)
  },
  {
    name: indexbqrISPSquaMeta?.name ?? "product___en",
    path: indexbqrISPSquaMeta?.path ?? "/en/product",
    meta: indexbqrISPSquaMeta || {},
    alias: indexbqrISPSquaMeta?.alias || [],
    redirect: indexbqrISPSquaMeta?.redirect,
    component: () => import("/home/circleci/app/pages/product/index.vue").then(m => m.default || m)
  },
  {
    name: index78gaKrnwp8Meta?.name ?? "profile___ar",
    path: index78gaKrnwp8Meta?.path ?? "/ar/profile",
    meta: index78gaKrnwp8Meta || {},
    alias: index78gaKrnwp8Meta?.alias || [],
    redirect: index78gaKrnwp8Meta?.redirect,
    component: () => import("/home/circleci/app/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: index78gaKrnwp8Meta?.name ?? "profile___en",
    path: index78gaKrnwp8Meta?.path ?? "/en/profile",
    meta: index78gaKrnwp8Meta || {},
    alias: index78gaKrnwp8Meta?.alias || [],
    redirect: index78gaKrnwp8Meta?.redirect,
    component: () => import("/home/circleci/app/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexaYT6T4U9TtMeta?.name ?? "referral___ar",
    path: indexaYT6T4U9TtMeta?.path ?? "/ar/referral",
    meta: indexaYT6T4U9TtMeta || {},
    alias: indexaYT6T4U9TtMeta?.alias || [],
    redirect: indexaYT6T4U9TtMeta?.redirect,
    component: () => import("/home/circleci/app/pages/referral/index.vue").then(m => m.default || m)
  },
  {
    name: indexaYT6T4U9TtMeta?.name ?? "referral___en",
    path: indexaYT6T4U9TtMeta?.path ?? "/en/referral",
    meta: indexaYT6T4U9TtMeta || {},
    alias: indexaYT6T4U9TtMeta?.alias || [],
    redirect: indexaYT6T4U9TtMeta?.redirect,
    component: () => import("/home/circleci/app/pages/referral/index.vue").then(m => m.default || m)
  },
  {
    name: indexbwUeIYYri5Meta?.name ?? "refund___ar",
    path: indexbwUeIYYri5Meta?.path ?? "/ar/refund",
    meta: indexbwUeIYYri5Meta || {},
    alias: indexbwUeIYYri5Meta?.alias || [],
    redirect: indexbwUeIYYri5Meta?.redirect,
    component: () => import("/home/circleci/app/pages/refund/index.vue").then(m => m.default || m)
  },
  {
    name: indexbwUeIYYri5Meta?.name ?? "refund___en",
    path: indexbwUeIYYri5Meta?.path ?? "/en/refund",
    meta: indexbwUeIYYri5Meta || {},
    alias: indexbwUeIYYri5Meta?.alias || [],
    redirect: indexbwUeIYYri5Meta?.redirect,
    component: () => import("/home/circleci/app/pages/refund/index.vue").then(m => m.default || m)
  },
  {
    name: indexPChMWttK5VMeta?.name ?? "register___ar",
    path: indexPChMWttK5VMeta?.path ?? "/ar/register",
    meta: indexPChMWttK5VMeta || {},
    alias: indexPChMWttK5VMeta?.alias || [],
    redirect: indexPChMWttK5VMeta?.redirect,
    component: () => import("/home/circleci/app/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexPChMWttK5VMeta?.name ?? "register___en",
    path: indexPChMWttK5VMeta?.path ?? "/en/register",
    meta: indexPChMWttK5VMeta || {},
    alias: indexPChMWttK5VMeta?.alias || [],
    redirect: indexPChMWttK5VMeta?.redirect,
    component: () => import("/home/circleci/app/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexTpKOICGjV2Meta?.name ?? "reset___ar",
    path: indexTpKOICGjV2Meta?.path ?? "/ar/reset",
    meta: indexTpKOICGjV2Meta || {},
    alias: indexTpKOICGjV2Meta?.alias || [],
    redirect: indexTpKOICGjV2Meta?.redirect,
    component: () => import("/home/circleci/app/pages/reset/index.vue").then(m => m.default || m)
  },
  {
    name: indexTpKOICGjV2Meta?.name ?? "reset___en",
    path: indexTpKOICGjV2Meta?.path ?? "/en/reset",
    meta: indexTpKOICGjV2Meta || {},
    alias: indexTpKOICGjV2Meta?.alias || [],
    redirect: indexTpKOICGjV2Meta?.redirect,
    component: () => import("/home/circleci/app/pages/reset/index.vue").then(m => m.default || m)
  },
  {
    name: index8BU9ht3N8sMeta?.name ?? "reward-about___ar",
    path: index8BU9ht3N8sMeta?.path ?? "/ar/reward/about",
    meta: index8BU9ht3N8sMeta || {},
    alias: index8BU9ht3N8sMeta?.alias || [],
    redirect: index8BU9ht3N8sMeta?.redirect,
    component: () => import("/home/circleci/app/pages/reward/about/index.vue").then(m => m.default || m)
  },
  {
    name: index8BU9ht3N8sMeta?.name ?? "reward-about___en",
    path: index8BU9ht3N8sMeta?.path ?? "/en/reward/about",
    meta: index8BU9ht3N8sMeta || {},
    alias: index8BU9ht3N8sMeta?.alias || [],
    redirect: index8BU9ht3N8sMeta?.redirect,
    component: () => import("/home/circleci/app/pages/reward/about/index.vue").then(m => m.default || m)
  },
  {
    name: indexMbY3u8BDVaMeta?.name ?? "reward-banner___ar",
    path: indexMbY3u8BDVaMeta?.path ?? "/ar/reward/banner",
    meta: indexMbY3u8BDVaMeta || {},
    alias: indexMbY3u8BDVaMeta?.alias || [],
    redirect: indexMbY3u8BDVaMeta?.redirect,
    component: () => import("/home/circleci/app/pages/reward/banner/index.vue").then(m => m.default || m)
  },
  {
    name: indexMbY3u8BDVaMeta?.name ?? "reward-banner___en",
    path: indexMbY3u8BDVaMeta?.path ?? "/en/reward/banner",
    meta: indexMbY3u8BDVaMeta || {},
    alias: indexMbY3u8BDVaMeta?.alias || [],
    redirect: indexMbY3u8BDVaMeta?.redirect,
    component: () => import("/home/circleci/app/pages/reward/banner/index.vue").then(m => m.default || m)
  },
  {
    name: indexEMlrlLxNPyMeta?.name ?? "reward-details___ar",
    path: indexEMlrlLxNPyMeta?.path ?? "/ar/reward/details",
    meta: indexEMlrlLxNPyMeta || {},
    alias: indexEMlrlLxNPyMeta?.alias || [],
    redirect: indexEMlrlLxNPyMeta?.redirect,
    component: () => import("/home/circleci/app/pages/reward/details/index.vue").then(m => m.default || m)
  },
  {
    name: indexEMlrlLxNPyMeta?.name ?? "reward-details___en",
    path: indexEMlrlLxNPyMeta?.path ?? "/en/reward/details",
    meta: indexEMlrlLxNPyMeta || {},
    alias: indexEMlrlLxNPyMeta?.alias || [],
    redirect: indexEMlrlLxNPyMeta?.redirect,
    component: () => import("/home/circleci/app/pages/reward/details/index.vue").then(m => m.default || m)
  },
  {
    name: indexQyQSKNiz6nMeta?.name ?? "reward-history___ar",
    path: indexQyQSKNiz6nMeta?.path ?? "/ar/reward/history",
    meta: indexQyQSKNiz6nMeta || {},
    alias: indexQyQSKNiz6nMeta?.alias || [],
    redirect: indexQyQSKNiz6nMeta?.redirect,
    component: () => import("/home/circleci/app/pages/reward/history/index.vue").then(m => m.default || m)
  },
  {
    name: indexQyQSKNiz6nMeta?.name ?? "reward-history___en",
    path: indexQyQSKNiz6nMeta?.path ?? "/en/reward/history",
    meta: indexQyQSKNiz6nMeta || {},
    alias: indexQyQSKNiz6nMeta?.alias || [],
    redirect: indexQyQSKNiz6nMeta?.redirect,
    component: () => import("/home/circleci/app/pages/reward/history/index.vue").then(m => m.default || m)
  },
  {
    name: indexH5QALV0TphMeta?.name ?? "reward___ar",
    path: indexH5QALV0TphMeta?.path ?? "/ar/reward",
    meta: indexH5QALV0TphMeta || {},
    alias: indexH5QALV0TphMeta?.alias || [],
    redirect: indexH5QALV0TphMeta?.redirect,
    component: () => import("/home/circleci/app/pages/reward/index.vue").then(m => m.default || m)
  },
  {
    name: indexH5QALV0TphMeta?.name ?? "reward___en",
    path: indexH5QALV0TphMeta?.path ?? "/en/reward",
    meta: indexH5QALV0TphMeta || {},
    alias: indexH5QALV0TphMeta?.alias || [],
    redirect: indexH5QALV0TphMeta?.redirect,
    component: () => import("/home/circleci/app/pages/reward/index.vue").then(m => m.default || m)
  },
  {
    name: indexEENXWQEUD0Meta?.name ?? "reward-terms___ar",
    path: indexEENXWQEUD0Meta?.path ?? "/ar/reward/terms",
    meta: indexEENXWQEUD0Meta || {},
    alias: indexEENXWQEUD0Meta?.alias || [],
    redirect: indexEENXWQEUD0Meta?.redirect,
    component: () => import("/home/circleci/app/pages/reward/terms/index.vue").then(m => m.default || m)
  },
  {
    name: indexEENXWQEUD0Meta?.name ?? "reward-terms___en",
    path: indexEENXWQEUD0Meta?.path ?? "/en/reward/terms",
    meta: indexEENXWQEUD0Meta || {},
    alias: indexEENXWQEUD0Meta?.alias || [],
    redirect: indexEENXWQEUD0Meta?.redirect,
    component: () => import("/home/circleci/app/pages/reward/terms/index.vue").then(m => m.default || m)
  },
  {
    name: indexfWvQb0noKiMeta?.name ?? "share_cart-id___ar",
    path: indexfWvQb0noKiMeta?.path ?? "/ar/share_cart/:id()",
    meta: indexfWvQb0noKiMeta || {},
    alias: indexfWvQb0noKiMeta?.alias || [],
    redirect: indexfWvQb0noKiMeta?.redirect,
    component: () => import("/home/circleci/app/pages/share_cart/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfWvQb0noKiMeta?.name ?? "share_cart-id___en",
    path: indexfWvQb0noKiMeta?.path ?? "/en/share_cart/:id()",
    meta: indexfWvQb0noKiMeta || {},
    alias: indexfWvQb0noKiMeta?.alias || [],
    redirect: indexfWvQb0noKiMeta?.redirect,
    component: () => import("/home/circleci/app/pages/share_cart/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexW3jyGsCOwuMeta?.name ?? "success-id___ar",
    path: indexW3jyGsCOwuMeta?.path ?? "/ar/success/:id()",
    meta: indexW3jyGsCOwuMeta || {},
    alias: indexW3jyGsCOwuMeta?.alias || [],
    redirect: indexW3jyGsCOwuMeta?.redirect,
    component: () => import("/home/circleci/app/pages/success/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexW3jyGsCOwuMeta?.name ?? "success-id___en",
    path: indexW3jyGsCOwuMeta?.path ?? "/en/success/:id()",
    meta: indexW3jyGsCOwuMeta || {},
    alias: indexW3jyGsCOwuMeta?.alias || [],
    redirect: indexW3jyGsCOwuMeta?.redirect,
    component: () => import("/home/circleci/app/pages/success/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexYMns3cSdyaMeta?.name ?? "terms___ar",
    path: indexYMns3cSdyaMeta?.path ?? "/ar/terms",
    meta: indexYMns3cSdyaMeta || {},
    alias: indexYMns3cSdyaMeta?.alias || [],
    redirect: indexYMns3cSdyaMeta?.redirect,
    component: () => import("/home/circleci/app/pages/terms/index.vue").then(m => m.default || m)
  },
  {
    name: indexYMns3cSdyaMeta?.name ?? "terms___en",
    path: indexYMns3cSdyaMeta?.path ?? "/en/terms",
    meta: indexYMns3cSdyaMeta || {},
    alias: indexYMns3cSdyaMeta?.alias || [],
    redirect: indexYMns3cSdyaMeta?.redirect,
    component: () => import("/home/circleci/app/pages/terms/index.vue").then(m => m.default || m)
  },
  {
    name: indexBTX4e1g4tzMeta?.name ?? "tickets-id___ar",
    path: indexBTX4e1g4tzMeta?.path ?? "/ar/tickets/:id()",
    meta: indexBTX4e1g4tzMeta || {},
    alias: indexBTX4e1g4tzMeta?.alias || [],
    redirect: indexBTX4e1g4tzMeta?.redirect,
    component: () => import("/home/circleci/app/pages/tickets/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexBTX4e1g4tzMeta?.name ?? "tickets-id___en",
    path: indexBTX4e1g4tzMeta?.path ?? "/en/tickets/:id()",
    meta: indexBTX4e1g4tzMeta || {},
    alias: indexBTX4e1g4tzMeta?.alias || [],
    redirect: indexBTX4e1g4tzMeta?.redirect,
    component: () => import("/home/circleci/app/pages/tickets/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexMoQC1AZrdcMeta?.name ?? "tickets___ar",
    path: indexMoQC1AZrdcMeta?.path ?? "/ar/tickets",
    meta: indexMoQC1AZrdcMeta || {},
    alias: indexMoQC1AZrdcMeta?.alias || [],
    redirect: indexMoQC1AZrdcMeta?.redirect,
    component: () => import("/home/circleci/app/pages/tickets/index.vue").then(m => m.default || m)
  },
  {
    name: indexMoQC1AZrdcMeta?.name ?? "tickets___en",
    path: indexMoQC1AZrdcMeta?.path ?? "/en/tickets",
    meta: indexMoQC1AZrdcMeta || {},
    alias: indexMoQC1AZrdcMeta?.alias || [],
    redirect: indexMoQC1AZrdcMeta?.redirect,
    component: () => import("/home/circleci/app/pages/tickets/index.vue").then(m => m.default || m)
  },
  {
    name: indexmKw3gwvGzEMeta?.name ?? "track___ar",
    path: indexmKw3gwvGzEMeta?.path ?? "/ar/track",
    meta: indexmKw3gwvGzEMeta || {},
    alias: indexmKw3gwvGzEMeta?.alias || [],
    redirect: indexmKw3gwvGzEMeta?.redirect,
    component: () => import("/home/circleci/app/pages/track/index.vue").then(m => m.default || m)
  },
  {
    name: indexmKw3gwvGzEMeta?.name ?? "track___en",
    path: indexmKw3gwvGzEMeta?.path ?? "/en/track",
    meta: indexmKw3gwvGzEMeta || {},
    alias: indexmKw3gwvGzEMeta?.alias || [],
    redirect: indexmKw3gwvGzEMeta?.redirect,
    component: () => import("/home/circleci/app/pages/track/index.vue").then(m => m.default || m)
  },
  {
    name: indexMEXO7RDDiPMeta?.name ?? "videos-id___ar",
    path: indexMEXO7RDDiPMeta?.path ?? "/ar/videos/:id()",
    meta: indexMEXO7RDDiPMeta || {},
    alias: indexMEXO7RDDiPMeta?.alias || [],
    redirect: indexMEXO7RDDiPMeta?.redirect,
    component: () => import("/home/circleci/app/pages/videos/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexMEXO7RDDiPMeta?.name ?? "videos-id___en",
    path: indexMEXO7RDDiPMeta?.path ?? "/en/videos/:id()",
    meta: indexMEXO7RDDiPMeta || {},
    alias: indexMEXO7RDDiPMeta?.alias || [],
    redirect: indexMEXO7RDDiPMeta?.redirect,
    component: () => import("/home/circleci/app/pages/videos/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexVJHcnakILCMeta?.name ?? "videos___ar",
    path: indexVJHcnakILCMeta?.path ?? "/ar/videos",
    meta: indexVJHcnakILCMeta || {},
    alias: indexVJHcnakILCMeta?.alias || [],
    redirect: indexVJHcnakILCMeta?.redirect,
    component: () => import("/home/circleci/app/pages/videos/index.vue").then(m => m.default || m)
  },
  {
    name: indexVJHcnakILCMeta?.name ?? "videos___en",
    path: indexVJHcnakILCMeta?.path ?? "/en/videos",
    meta: indexVJHcnakILCMeta || {},
    alias: indexVJHcnakILCMeta?.alias || [],
    redirect: indexVJHcnakILCMeta?.redirect,
    component: () => import("/home/circleci/app/pages/videos/index.vue").then(m => m.default || m)
  },
  {
    name: indexbTxzeXEkqHMeta?.name ?? "voucher-id___ar",
    path: indexbTxzeXEkqHMeta?.path ?? "/ar/voucher/:id()",
    meta: indexbTxzeXEkqHMeta || {},
    alias: indexbTxzeXEkqHMeta?.alias || [],
    redirect: indexbTxzeXEkqHMeta?.redirect,
    component: () => import("/home/circleci/app/pages/voucher/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexbTxzeXEkqHMeta?.name ?? "voucher-id___en",
    path: indexbTxzeXEkqHMeta?.path ?? "/en/voucher/:id()",
    meta: indexbTxzeXEkqHMeta || {},
    alias: indexbTxzeXEkqHMeta?.alias || [],
    redirect: indexbTxzeXEkqHMeta?.redirect,
    component: () => import("/home/circleci/app/pages/voucher/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexHP0TIXDrBeMeta?.name ?? "voucher-checkout___ar",
    path: indexHP0TIXDrBeMeta?.path ?? "/ar/voucher/checkout",
    meta: indexHP0TIXDrBeMeta || {},
    alias: indexHP0TIXDrBeMeta?.alias || [],
    redirect: indexHP0TIXDrBeMeta?.redirect,
    component: () => import("/home/circleci/app/pages/voucher/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexHP0TIXDrBeMeta?.name ?? "voucher-checkout___en",
    path: indexHP0TIXDrBeMeta?.path ?? "/en/voucher/checkout",
    meta: indexHP0TIXDrBeMeta || {},
    alias: indexHP0TIXDrBeMeta?.alias || [],
    redirect: indexHP0TIXDrBeMeta?.redirect,
    component: () => import("/home/circleci/app/pages/voucher/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexNpU8sKAnr4Meta?.name ?? "voucher___ar",
    path: indexNpU8sKAnr4Meta?.path ?? "/ar/voucher",
    meta: indexNpU8sKAnr4Meta || {},
    alias: indexNpU8sKAnr4Meta?.alias || [],
    redirect: indexNpU8sKAnr4Meta?.redirect,
    component: () => import("/home/circleci/app/pages/voucher/index.vue").then(m => m.default || m)
  },
  {
    name: indexNpU8sKAnr4Meta?.name ?? "voucher___en",
    path: indexNpU8sKAnr4Meta?.path ?? "/en/voucher",
    meta: indexNpU8sKAnr4Meta || {},
    alias: indexNpU8sKAnr4Meta?.alias || [],
    redirect: indexNpU8sKAnr4Meta?.redirect,
    component: () => import("/home/circleci/app/pages/voucher/index.vue").then(m => m.default || m)
  },
  {
    name: indexeedUBFpVyEMeta?.name ?? "voucher-success-id___ar",
    path: indexeedUBFpVyEMeta?.path ?? "/ar/voucher/success/:id()",
    meta: indexeedUBFpVyEMeta || {},
    alias: indexeedUBFpVyEMeta?.alias || [],
    redirect: indexeedUBFpVyEMeta?.redirect,
    component: () => import("/home/circleci/app/pages/voucher/success/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexeedUBFpVyEMeta?.name ?? "voucher-success-id___en",
    path: indexeedUBFpVyEMeta?.path ?? "/en/voucher/success/:id()",
    meta: indexeedUBFpVyEMeta || {},
    alias: indexeedUBFpVyEMeta?.alias || [],
    redirect: indexeedUBFpVyEMeta?.redirect,
    component: () => import("/home/circleci/app/pages/voucher/success/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexyUVzshJU8GMeta?.name ?? "wallet___ar",
    path: indexyUVzshJU8GMeta?.path ?? "/ar/wallet",
    meta: indexyUVzshJU8GMeta || {},
    alias: indexyUVzshJU8GMeta?.alias || [],
    redirect: indexyUVzshJU8GMeta?.redirect,
    component: () => import("/home/circleci/app/pages/wallet/index.vue").then(m => m.default || m)
  },
  {
    name: indexyUVzshJU8GMeta?.name ?? "wallet___en",
    path: indexyUVzshJU8GMeta?.path ?? "/en/wallet",
    meta: indexyUVzshJU8GMeta || {},
    alias: indexyUVzshJU8GMeta?.alias || [],
    redirect: indexyUVzshJU8GMeta?.redirect,
    component: () => import("/home/circleci/app/pages/wallet/index.vue").then(m => m.default || m)
  },
  {
    name: indexylMkKt1yfZMeta?.name ?? "blog-details___ar",
    path: indexylMkKt1yfZMeta?.path ?? "/ar/:lang?/blogs/:id(.*-n\\d+.*)",
    meta: indexylMkKt1yfZMeta || {},
    alias: indexylMkKt1yfZMeta?.alias || [],
    redirect: indexylMkKt1yfZMeta?.redirect,
    component: () => import("/home/circleci/app/pages/blogs/details/index.vue").then(m => m.default || m)
  },
  {
    name: indexylMkKt1yfZMeta?.name ?? "blog-details___en",
    path: indexylMkKt1yfZMeta?.path ?? "/en/:lang?/blogs/:id(.*-n\\d+.*)",
    meta: indexylMkKt1yfZMeta || {},
    alias: indexylMkKt1yfZMeta?.alias || [],
    redirect: indexylMkKt1yfZMeta?.redirect,
    component: () => import("/home/circleci/app/pages/blogs/details/index.vue").then(m => m.default || m)
  },
  {
    name: indexrV82Pl3rfGMeta?.name ?? "blogs___ar",
    path: indexrV82Pl3rfGMeta?.path ?? "/ar/:lang?/blogs/:category?",
    meta: indexrV82Pl3rfGMeta || {},
    alias: indexrV82Pl3rfGMeta?.alias || [],
    redirect: indexrV82Pl3rfGMeta?.redirect,
    component: () => import("/home/circleci/app/pages/blogs/index.vue").then(m => m.default || m)
  },
  {
    name: indexrV82Pl3rfGMeta?.name ?? "blogs___en",
    path: indexrV82Pl3rfGMeta?.path ?? "/en/:lang?/blogs/:category?",
    meta: indexrV82Pl3rfGMeta || {},
    alias: indexrV82Pl3rfGMeta?.alias || [],
    redirect: indexrV82Pl3rfGMeta?.redirect,
    component: () => import("/home/circleci/app/pages/blogs/index.vue").then(m => m.default || m)
  },
  {
    name: indexbqrISPSquaMeta?.name ?? "product-details___ar",
    path: indexbqrISPSquaMeta?.path ?? "/ar/:lang?/:category?/:scategory?/:sscategory?/:id(.*-n\\d+.*)",
    meta: indexbqrISPSquaMeta || {},
    alias: indexbqrISPSquaMeta?.alias || [],
    redirect: indexbqrISPSquaMeta?.redirect,
    component: () => import("/home/circleci/app/pages/product/index.vue").then(m => m.default || m)
  },
  {
    name: indexbqrISPSquaMeta?.name ?? "product-details___en",
    path: indexbqrISPSquaMeta?.path ?? "/en/:lang?/:category?/:scategory?/:sscategory?/:id(.*-n\\d+.*)",
    meta: indexbqrISPSquaMeta || {},
    alias: indexbqrISPSquaMeta?.alias || [],
    redirect: indexbqrISPSquaMeta?.redirect,
    component: () => import("/home/circleci/app/pages/product/index.vue").then(m => m.default || m)
  }
]