<template>
  <van-popup v-model:show="isSmartBannerShown"
             :close-icon-position="$i18n.locale === 'en' ? 'top-right' : 'top-left'"
             :lock-scroll="false"
             :overlay="false" class="shadow-cus" position="bottom" v-bind="$attrs">
    <div class="bg-secondary text-white text-xs px-3 py-2 font-bold"
         style="background: linear-gradient(0deg, rgba(246, 93, 157, 0.90) 0%, rgba(246, 93, 157, 0.90) 100%), #FFF;">
      {{ $t('smart.discount') }}
    </div>
    <div class="p-3 flex flex-col justify-between h-full">
      <div class="h-full mb-4">
        <div class="flex items-center gap-4">
          <niceone-image :src="$publicPath('/images/pwa-logo.png')" class="rounded-xl" width="64"/>
          <div class="flex-grow">
            <div class="flex justify-between items-center pb-2">
              <span class="font-semibold">{{ $t('smart.continue_on_the_app') }}</span>
              <van-rate :model-value="5" allow-half class="pe-2" color="#f5c523" readonly
                        size="18" void-color="#eee" void-icon="star"/>
            </div>
            <p class="text-xs text-grey-muted">{{ $t('smart.download_the_app_and_register_to_get_50_sar_added') }}</p>
          </div>
        </div>
      </div>
      <div class="flex-center gap-4 mb-2">
        <van-button block class="!rounded-md" plain size="normal" type="primary" @click="hideSmartBanner">
          {{ $t('smart.stay_on_the_web') }}
        </van-button>
        <van-button block class="!rounded-md !border" size="normal" type="primary" @click.prevent="onOpenApp">
          {{ $t('smart.go_to_app') }}
        </van-button>
      </div>
    </div>
  </van-popup>
</template>

<script setup>
import {track} from "@/composables/useTrackEvent";
import {TRACKERS} from "@/constants/events";
import {COOKIE_KEYS} from "@/constants/cookie";
import {useContentStore} from "@/store/content";
import {ONE_YEAR_IN_SECONDS} from "@/constants";

const contentStore = useContentStore()
const {isSmartBannerShown} = storeToRefs(contentStore)

const hideSmartBanner = () => {
  useCookie(COOKIE_KEYS.SMART_BANNER, {maxAge: ONE_YEAR_IN_SECONDS, secure: true}).value = true
}

const onOpenApp = () => {
  track(TRACKERS.OPEN_APP_FROM_SMART_BANNER)
  hideSmartBanner()
  window.open(
      useNuxtApp().$device.isAndroid
          ? 'https://play.google.com/store/apps/details?id=com.NiceOne.App'
          : 'https://apps.apple.com/sa/app/nice-one-%D9%86%D8%A7%D9%8A%D8%B3-%D9%88%D9%86/id1271754138',
      '_blank'
  )
}
</script>

<style lang="scss" scoped>

</style>