<template>
  <video-list :on-loading="onLoading" :isLoading="isLoading" :videos="videos" @close="$emit('close')" is-close/>
</template>

<script setup>
import VideoList from '@/components/dynamic_components/video-slider/Video'
import {getVideoDetailsByIdMutation} from "@/plugins/API/home";
import {track} from "@/composables/useTrackEvent";
import {TRACKERS} from "@/constants/events";

const videos = ref([])
const {isLoading, mutateAsync} = getVideoDetailsByIdMutation()
const onLoading = ref(true)
const props = defineProps({
  videoId: {
    type: [Number, String],
    default: 0
  }
})

mutateAsync(props?.videoId).then(({data}) => {
  videos.value = data?.videos || []
  onLoading.value = false
  onVideoOpened()
})

const onVideoOpened = () => {
  track(TRACKERS.OPEN_VIDEO_PAGE, {video_id: videos.value[0]?.id, video_name: videos.value[0]?.title_en})
}
</script>