<template>
  <div class="grid gap-2 grid-cols-3 pb-3">
    <product v-for="(product, index) in products" :key="`product-${index}`" :hide-fav-btn="hideFavBtn"
             :padding="8" :product="product" :source-data="setEventsData(product)" class="!min-w-[80px]" hide-reviews
             hide-tags>
      <template #image>
        <niceone-image :src="product.thumb" :width="imageWidth" class="mx-auto"/>
      </template>
      <template #price>
        <price :min-height="0" :product="product" hide-line-through/>
      </template>
    </product>
  </div>
</template>

<script setup>
import Price from "@/components/product/Price";

const props = defineProps({
  products: {
    type: Array,
    default: () => []
  },
  hideFavBtn: {
    type: Boolean,
    default: false
  },
  imageWidth: {
    type: Number,
    default: 100
  },
  sourceData: {
    type: Object,
    default: {}
  }
})

const setEventsData = (product) => !props?.sourceData.ISPLP ? props?.sourceData : {
  source_type: props?.sourceData.source_type,
  source_name: product?.en_name,
  source_id: product.id
}
</script>

<style scoped>

</style>