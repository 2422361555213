import {useAuthStore} from "~/store/auth";
import {useEventBus} from "@vueuse/core";
import {EVENTS} from "~/constants/events";
import {COOKIE_KEYS} from "~/constants/cookie";
import useCustomConfig from "~/composables/useEnvConfig";
import {APP_VERSION} from "@/constants/version";

export default defineNuxtPlugin(() => {
    const localPath = useLocalePath()
    const routerUrl = (url) => {

        if (!url) return localPath('/')

        if (url.length && url.length > 1 && !url.startsWith('/')) {
            url = `/${url}`
        }

        if (url.startsWith('/en/') || url.startsWith('/ar/')) {
            url = url.substring(3)
        }

        return localPath(url)
    }

    const config = useCustomConfig();
    const publicPath = (url = '') => `${config.public.CDN_BASE_URL}${url}?v=${APP_VERSION}`

    const redirectAfterLogin = () => {
        const router = useRouter()
        const {emit: emitAuth} = useEventBus(EVENTS.AUTH_DIALOG);
        const authStore = useAuthStore()
        const {pendingRedirection, blockRedirection} = storeToRefs(authStore)

        // If navigation is block just hide the sidebar
        if (blockRedirection.value) return emitAuth({show: false})

        const pendingRoute = useCookie(COOKIE_KEYS.PENDING_ROUTE, {secure: true});
        const redirection = pendingRedirection.value || pendingRoute.value
        if (redirection) {
            router.push(useNuxtApp().$routerUrl(redirection))
            pendingRoute.value = ""
            pendingRedirection.value = ""
        } else {
            router.push(useNuxtApp().$routerUrl('/'))
        }

        emitAuth({show: false})
    }
    return {
        provide: {
            routerUrl,
            publicPath,
            redirectAfterLogin
        },
    };
});